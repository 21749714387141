import React from 'react'

import Footer from './Footer'
import card from '../assets/images/card.png'

const Header = () => (
  <header id="header">
    <div className="inner">
      {/* <a href="#" className="image avatar"> */}
      <a href="/#" className="image">
        <img src={card} alt="Poz & Lolo Fencing 204-289-0855" />
      </a>
      <h1>
        <strong>Pozlola Fencing</strong>, temporary fencing to meet your
        business and event needs.
      </h1>
    </div>
    <Footer />
  </header>
)

export default Header
