import React from 'react'
import Helmet from 'react-helmet'

import Gallery from '../components/Gallery'
import Layout from '../components/layout'

const HomeIndex = () => {
  const siteTitle =
    'Pozlola Fencing | Temporary Fencing serving Winnipeg and surrounding area.'
  const siteDescription =
    'Pozlola Fencing provides temporary fencing needs for your home, business, or event.'

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
        <meta name="description" content={siteDescription} />
        <meta
          name="google-site-verification"
          content="vXIS1n2mPa7cDi8Zdk58OHCwGOmkl4DWqBeVk9ZFmOg"
        />
      </Helmet>

      <div id="main">
        <section id="one">
          <header className="major">
            <h2>Why Pozlola Fencing?</h2>
          </header>
          <ul>
            <li>
              Superior PVC coated black fencing won’t make your space look like
              a construction zone
            </li>
            <li>Competitive rates with all local fencing companies</li>
            <li>Minimum signage keeping your area neat</li>
            <li>Unmatched customer service</li>
            <li>
              We will personally set up the fencing to best meet your needs
            </li>
            <li>
              Locally owned and operated, serving Winnipeg and the surrounding
              area
            </li>
          </ul>
          {/*      <ul className="actions">
            <li>
              <a href="#" className="button">
                Learn More
              </a>
            </li>
          </ul> */}
        </section>

        <section id="two">
          <h2>Fencing</h2>
          <p>
            Black temporary fencing panels are 6' high by 9'6" wide.
            <br />
            Additional colors and sizes available upon request.
          </p>
          <Gallery />

          {/*  <ul className="actions">
            <li>
              <a href="#" className="button">
                Full Portfolio
              </a>
            </li>
          </ul> */}
        </section>

        <section id="three">
          <h2>Get In Touch</h2>
          <p>Contact us with any questions or to receive a quote.</p>
          <div className="row">
            {/*  <div className="8u 12u$(small)">
              <form method="post" action="#">
                <div className="row uniform 50%">
                  <div className="6u 12u$(xsmall)">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Name"
                    />
                  </div>
                  <div className="6u 12u$(xsmall)">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="12u">
                    <textarea
                      name="message"
                      id="message"
                      placeholder="Message"
                      rows="4"
                    ></textarea>
                  </div>
                </div>
                <ul className="actions" style={{ marginTop: 30 }}>
                  <li>
                    <input type="submit" value="Send Message" />
                  </li>
                </ul>
              </form>
            </div> */}
            <div className="4u 12u$(small)" id="contactUs">
              <ul className="labeled-icons">
                <li>
                  <h3 className="icon fa-envelope-o">
                    <span className="label">Email</span>
                  </h3>
                  <a href="mailto:pozlolafencing@gmail.com">
                    pozlolafencing@gmail.com
                  </a>
                </li>
                <li>
                  <h3 className="icon fa-mobile">
                    <span className="label">Phone</span>
                  </h3>
                  <a href="tel:2042890855">204-289-0855</a>
                </li>
                <li>
                  <h3 className="icon fa-home">
                    <span className="label">Address</span>
                  </h3>
                  Winnipeg, Manitoba
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default HomeIndex
