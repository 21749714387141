import React from 'react'

const Footer = () => (
  <div id="footer">
    <div className="inner">
      <ul className="icons">
        <li>
          <a href="#contactUs">
            <span className="label">Contact Us</span>
          </a>
        </li>

        <li>
          <a
            href="mailto:pozlolafencing@gmail.com"
            className="icon fa-envelope-o"
          >
            <span className="label">Email</span>
          </a>
        </li>
        <li>
          <a href="tel:2042890855" className="icon fa-phone">
            <span className="label">Phone</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
)

export default Footer
