import full04 from '../../../assets/images/fulls/1.png'
// import full05 from '../../../assets/images/fulls/2.png'
import full03 from '../../../assets/images/fulls/3.png'
import full01 from '../../../assets/images/fulls/4.png'
import full02 from '../../../assets/images/fulls/5.png'
import full06 from '../../../assets/images/fulls/6.png'
import full07 from '../../../assets/images/fulls/7.png'
// import full08 from '../../../assets/images/fulls/8.png'
import thumb04 from '../../../assets/images/thumbs/1.png'
// import thumb05 from '../../../assets/images/thumbs/2.png'
import thumb03 from '../../../assets/images/thumbs/3.png'
import thumb01 from '../../../assets/images/thumbs/4.png'
import thumb02 from '../../../assets/images/thumbs/5.png'
import thumb06 from '../../../assets/images/thumbs/6.png'
import thumb07 from '../../../assets/images/thumbs/7.png'
// import thumb08 from '../../../assets/images/thumbs/8.png'

export const DEFAULT_IMAGES = [
  {
    id: '1',
    source: full01,
    thumbnail: thumb01,
    // caption: 'Photo 1',
    // description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
  },
  {
    id: '2',
    source: full02,
    thumbnail: thumb02,
    // caption: 'Photo 2',
    // description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
  },
  {
    id: '3',
    source: full03,
    thumbnail: thumb03,
    // caption: 'Photo 3',
    // description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
  },
  {
    id: '4',
    source: full04,
    thumbnail: thumb04,
    // caption: 'Photo 4',
    // description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
  },
  /*   {
    id: '5',
    source: full05,
    thumbnail: thumb05,
    caption: 'Photo 5',
    description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
  }, */
  {
    id: '6',
    source: full06,
    thumbnail: thumb06,
    // caption: 'Photo 6',
    // description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
  },
  {
    id: '7',
    source: full07,
    thumbnail: thumb07,
    // caption: 'Photo 7',
    // description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
  },
  /*   {
    id: '8',
    source: full08,
    thumbnail: thumb08,
    caption: 'Photo 8',
    description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
  }, */
]
